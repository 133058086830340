<template>
  <div>
    <d-body-top-bar :title="$t('views.credits-packs.credit-pack-create-update.add-credit-pack')">
      <div slot="tree">
        <label class="inactive-tree">
          {{ $t('views.subscriptions.subscriptions-list') }} /
          <label class="inactive-tree link-area pointer" @click="$router.push({name: 'credits_packs_list'})">
            {{ $t('views.credits-packs.credits-pack') }} /
          </label>
        </label>
        <label class="bg-doinsport-primary ml-1">
          {{ $t('views.credits-packs.credit-pack-create-update.add-credit-pack') }}</label>
      </div>
      <div slot="icons">
      </div>
    </d-body-top-bar>
    <div class="container-fluid credits-card-container">
      <b-card class="credits-card">
        <validation-observer
          ref="creditPackObserver"
          slim
        >
          <b-row>
            <b-col :class="widthCheck (1100) ? 'col-divider-5': ''" :cols="widthCheck (1100) ? '' : 12">
              <credit-pack-informations
                :credit-pack="creditPack"
                :validator="validator"
                class="mt-3"
              />
            </b-col>
            <div v-if="widthCheck (1100)" class="split-layout__divider">
              <div class="split-layout__rule"></div>
              <div class="split-layout__rule"></div>
            </div>
            <b-col :cols="widthCheck (1100) ? 6 : 12">
              <credit-pack-items
                :credit-pack-items="creditPack.items"
                :validator="validator"
              />
            </b-col>
          </b-row>
        </validation-observer>
        <b-row>
          <b-col align="right">
            <d-button
              text="general.actions.save"
              class="d-btn-sm font-text-title btn d-btn-danger font-text-title"
              @on:button-click="onSave()"
            />
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>
<script>
import {SUCCESS} from "@plugins/flash";
import CreditsPack from "@/classes/doinsport/CreditsPack";
import {
  getCreditsPack,
  postCreditsPack,
  putCreditsPack
} from "@api/doinsport/services/payment-token-packs/payment-token-packs.api";

export default {
  data: () => ({
    creditPack: new CreditsPack(),
    validator: {state: false, valid: true},
  }),
  components: {
    CreditPackInformations: () => import('./credit-pack-informations/Index'),
    CreditPackItems: () => import('./credit-pack-items/Index')
  },
  methods: {
    widthCheck(check) {
      return this.$store.getters['layout/getInnerWidth'] > check;
    },
    onSave() {
      this.validator.state = !this.validator.state;
      this.validator.valid = true;

      try {
        this.$refs.creditPackObserver.validate().then((valid) => {
          if (valid && this.validator.valid) {
            const deserializedCreditPack = new CreditsPack(this.creditPack, {deserialize: true});

            if (this.creditPack.id === null) {
              postCreditsPack(deserializedCreditPack)
                .then((response) => {
                  this.$flash(null, this.$t('general.actions.success-update'), 5000, SUCCESS);
                  this.$router.push({name: 'credits_packs_list'});
                })
              ;
            } else {
              putCreditsPack(deserializedCreditPack)
                .then((response) => {
                  this.$flash(null, this.$t('general.actions.success-update'), 5000, SUCCESS);
                  this.$router.push({name: 'credits_packs_list'});
                })
              ;
            }
          }
        })
      } catch (e) {
      }
    }
  },
  created() {
    if (this.$route.params.id) {
      getCreditsPack(this.$route.params.id)
        .then((response) => {
          this.creditPack = new CreditsPack(response.data, {serialize: true});

          for (const item of this.creditPack.items) {
            item.id = item['@id'];
            item.paymentToken = item.paymentToken['@id'];
          }
        })
      ;
    }
  }
}
</script>

<style scoped>
.credits-card .card-body {
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  padding-top: .5rem;
  padding-bottom: 1.0rem;
  padding-left: 1.0rem;
  padding-right: 1.0rem;
}

@media only screen and (max-width: 1151px) {
  .credits-card-container {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.credits-card-container .card {
  box-shadow: 0px 10px 16px #E2E1E6;
  border-radius: 8px;
}

</style>
